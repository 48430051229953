import React, { useState } from "react";
import { Modal, Header, Button, Input } from "semantic-ui-react";
import {
  allowBuzzes,
  markWrong,
  markCorrectAndContinue,
  pullQuestionAfterWrongAnswers,
  submitDailyDoubleWager,
  markWrongAndContinue,
} from "../../game/host";
import ReactMarkdown from "react-markdown";
export default ({
  activeRound,
  pointsAtStake,
  scores,
  activeQuestionIsDailyDouble,
  activeQuestionText,
  buzzedInPlayerId,
  players,
  playerWithBoardControl,
  activeAnswerText,
  buzzable,
  roomId,
}) => {
  return (
    <Modal open={!!activeQuestionText}>
      {!pointsAtStake ? ( // the host way of deriving activeQuestionNeedsDailyDoubleWager
        <DailyDoubleWagerModalContent
          activeRound={activeRound}
          players={players}
          playerWithBoardControl={playerWithBoardControl}
          scores={scores}
          onSubmit={(wager) => {
            submitDailyDoubleWager(roomId, wager);
          }}
        />
      ) : (
        <>
          <Header>
            {buzzedInPlayerId ? players[buzzedInPlayerId].name : "Nobody"} has
            buzzed in
          </Header>
          <Modal.Content>
            <ReactMarkdown source={activeQuestionText} />
            <ReactMarkdown source={activeAnswerText} />
          </Modal.Content>
          <Modal.Actions>
            {activeQuestionIsDailyDouble ? (
              <DailyDoubleActions
                roomId={roomId}
                playerWithBoardControl={playerWithBoardControl}
                pointsAtStake={pointsAtStake}
                scores={scores}
                activeAnswerText={activeAnswerText}
              />
            ) : (
              <RegularActions
                buzzable={buzzable}
                roomId={roomId}
                buzzedInPlayerId={buzzedInPlayerId}
                pointsAtStake={pointsAtStake}
                scores={scores}
                activeAnswerText={activeAnswerText}
              />
            )}
          </Modal.Actions>
        </>
      )}
    </Modal>
  );
};

function DailyDoubleWagerModalContent({
  players,
  playerWithBoardControl,
  scores,
  activeRound,
  onSubmit,
}) {
  const minWager = 5;
  const boardMax = activeRound.type === "double_jeopardy" ? 2000 : 1000;
  const playerScore =
    scores && scores[playerWithBoardControl]
      ? scores[playerWithBoardControl]
      : 0;
  const maxWager = Math.max(boardMax, playerScore);
  const [wager, setWager] = useState(maxWager);
  return (
    <>
      <Header>Daily Double!</Header>
      <Modal.Content>
        <p>
          How much would {players[playerWithBoardControl].name} like to wager?
          (Between {minWager}-{maxWager})
        </p>
        <Input
          value={wager}
          type="number"
          onChange={(_, { value }) => {
            setWager(value);
          }}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button
          primary
          content="Submit Wager"
          disabled={wager < minWager || wager > maxWager}
          onClick={() => {
            onSubmit(wager);
          }}
        />
      </Modal.Actions>
    </>
  );
}

function DailyDoubleActions({
  roomId,
  playerWithBoardControl,
  scores,
  pointsAtStake,
  activeAnswerText,
}) {
  return (
    <>
      <Button
        negative
        onClick={() =>
          markWrongAndContinue(
            roomId,
            playerWithBoardControl,
            scores,
            pointsAtStake,
            activeAnswerText
          )
        }
        content="WRONG"
      />
      <Button
        positive
        onClick={() =>
          markCorrectAndContinue(
            roomId,
            playerWithBoardControl,
            pointsAtStake,
            scores,
            activeAnswerText
          )
        }
        content="Correct"
      />
    </>
  );
}

function RegularActions({
  roomId,
  activeAnswerText,
  buzzedInPlayerId,
  buzzable,
  scores,
  pointsAtStake,
}) {
  return (
    <>
      {buzzedInPlayerId ? (
        <>
          <Button
            negative
            onClick={() =>
              markWrong(roomId, buzzedInPlayerId, scores, pointsAtStake)
            }
            content="WRONG"
          />
          <Button
            positive
            onClick={() =>
              markCorrectAndContinue(
                roomId,
                buzzedInPlayerId,
                pointsAtStake,
                scores,
                activeAnswerText
              )
            }
            content="Correct"
          />
        </>
      ) : buzzable ? (
        <Button
          primary
          onClick={() =>
            pullQuestionAfterWrongAnswers(roomId, activeAnswerText)
          }
          content="Nobody got it"
        />
      ) : (
        <Button
          primary
          onClick={() => allowBuzzes(roomId)}
          content="Allow Buzzes"
        />
      )}
    </>
  );
}
