import React, { useContext } from "react";
import { Message, Segment, Header, Icon } from "semantic-ui-react";
import { PlayerContext } from "../../store/PlayerStore";
import Board from "../Board";
import FinalScores from "../FinalScores";
import Loader from "../Loader";
import PlayerAwareBoardMessage from "../PlayerAwareBoardMessage";
import PlayerList from "../PlayerList";
import Buzzer from "./Buzzer";
import FinalJeopardy from "./FinalJeopardy";
import KeyBasedBuzzer from "./KeyBasedBuzzer";
import FinalJeopardyWager from "./FinalJeopardyWager";
import PageTitle from "../PageTitle";

export default ({ returnToForm }) => {
  const {
    roomDoesNotExist,
    roomId,
    playerId,
    game: {
      activeQuestionNeedsDailyDoubleWager,
      activeQuestionText,
      activeAnswerText,
      scores,
      players,
      activeRound,
      buzzable,
      playerWithBoardControl,
      buzzedInPlayerId,
      loadingNextRound,
      finalJeopardyAnswers,
      winner,
    },
  } = useContext(PlayerContext);

  return (
    <>
      <KeyBasedBuzzer />
      <PageTitle roundType={activeRound && activeRound.type} />
      {roomDoesNotExist ? (
        <Message
          error
          header="Room doesn't exist"
          content="Double check your room code and try again."
        />
      ) : loadingNextRound ? (
        <Loader isLoading />
      ) : (
        <>
          {buzzedInPlayerId ? (
            <PlayerAwareBoardMessage
              currentPlayerPlayerId={playerId}
              playerId={buzzedInPlayerId}
              players={players}
              header="buzzed in."
              message="can answer the question."
            />
          ) : null}
          {activeRound &&
          activeRound.type === "final_jeopardy" &&
          activeRound.stage === "wager" ? (
            <FinalJeopardyWager
              roomId={roomId}
              playerId={playerId}
              activeRound={activeRound}
              scores={scores}
            />
          ) : null}
          {activeRound &&
          activeRound.type === "final_jeopardy" &&
          activeRound.stage === "answer" ? (
            <FinalJeopardy
              roomId={roomId}
              playerId={playerId}
              activeRound={activeRound}
              scores={scores}
            />
          ) : null}
          {playerWithBoardControl && !activeQuestionText ? (
            <PlayerAwareBoardMessage
              currentPlayerPlayerId={playerId}
              playerId={playerWithBoardControl}
              players={players}
              header="control of the board."
              message="pick the next question."
            />
          ) : null}
          {activeRound &&
          activeRound.type !== "final_jeopardy" &&
          activeQuestionNeedsDailyDoubleWager ? (
            <Segment placeholder>
              <Header icon>
                <Icon name="exclamation circle" />
                DAILY DOUBLE
              </Header>
            </Segment>
          ) : null}
          {activeRound &&
          activeRound.type !== "final_jeopardy" &&
          !activeQuestionNeedsDailyDoubleWager ? (
            <Buzzer
              buzzable={buzzable}
              playerId={playerId}
              roomId={roomId}
              activeQuestionText={activeQuestionText}
              activeAnswerText={activeAnswerText}
            />
          ) : null}
          {activeRound && activeRound.type !== "final_jeopardy" ? (
            <Board activeRound={activeRound} hostMode={false} />
          ) : null}
          {finalJeopardyAnswers ? (
            <FinalScores
              scores={scores}
              players={players}
              finalJeopardyAnswers={finalJeopardyAnswers}
              winner={winner}
            />
          ) : (
            <PlayerList players={players} scores={scores} />
          )}
        </>
      )}
    </>
  );
};
