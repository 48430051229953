import React from "react";
import { Header, List, Button, Segment } from "semantic-ui-react";
import { revealFinalJeopardyQuestion } from "../../game/host";

export default ({
  activeRound,
  roomId,
  gameId,
  finalJeopardyWagers,
  players,
}) => (
  <Segment>
    <Header>{activeRound.category}</Header>
    {Object.entries(finalJeopardyWagers || {}).map(
      ([key, { wager, playerId }]) => (
        <List.Item key={`fjwagerlist${key}`}>
          <List.Content>
            <List.Header>{players[playerId].name}</List.Header>${wager}
          </List.Content>
        </List.Item>
      )
    )}
    Wagers of the players will appear here as they come in. When everyone's
    wager is submitted, click the button below to reveal the question to them.
    <Button
      positive
      primary
      size="huge"
      content="SHOW QUESTION"
      onClick={() => {
        revealFinalJeopardyQuestion(roomId, gameId);
      }}
    />
  </Segment>
);
