import React from "react";
import { Button, Header, Icon, Segment } from "semantic-ui-react";
import { buzz } from "../../game/player";
import ReactMarkdown from "react-markdown";

const determineTitle = (activeQuestionText, activeAnswerText) => {
  if (!!activeQuestionText) {
    return <ReactMarkdown source={activeQuestionText} />;
  } else if (!activeQuestionText && !!activeAnswerText) {
    return <ReactMarkdown source={activeAnswerText} />;
  } else {
    return "No question yet";
  }
};

export default ({
  buzzable,
  playerId,
  activeQuestionText,
  activeAnswerText,
  roomId,
}) => {
  return (
    <Segment placeholder>
      <Header icon>
        <Icon name="game" />
        {determineTitle(activeQuestionText, activeAnswerText)}
      </Header>
      <Segment.Inline>
        <Button
          primary
          size="huge"
          content={buzzable ? "BUZZ" : "WAIT"}
          disabled={!buzzable}
          onClick={() => buzz(roomId, playerId)}
        />
      </Segment.Inline>
    </Segment>
  );
};
