import React from "react";
import { Button, Header, Segment, Message } from "semantic-ui-react";
import { endGame } from "../../game/host";
import FinalJeopardyAnswerList from "./FinalJeopardyAnswerList";
import ReactMarkdown from "react-markdown";

export default ({
  activeRound,
  roomId,
  finalJeopardyAnswer,
  finalJeopardyAnswers,
  finalJeopardyWagers,
  players,
  scores,
}) => {
  return (
    <Segment>
      <Header>{activeRound.category}</Header>
      <Message
        header="Question"
        content={<ReactMarkdown source={activeRound.question} />}
      />
      <Message
        header="Answer (DO NOT READ ALOUD!!!)"
        content={<ReactMarkdown source={finalJeopardyAnswer || ""} />}
      />
      <Message>
        Players' answers will appear below. Click the green "+" button to mark
        it right or the red "-" button to mark it wrong. When everyone has
        submitted an answer and you've graded them all, click "END GAME" to show
        the players the final scores.
      </Message>
      <FinalJeopardyAnswerList
        finalJeopardyAnswers={finalJeopardyAnswers}
        finalJeopardyWagers={finalJeopardyWagers}
        players={players}
        roomId={roomId}
      />

      <Button
        negative
        size="huge"
        content="END GAME"
        onClick={() => endGame(roomId, players, scores)}
      />
    </Segment>
  );
};
