import firebase from "firebase/app";
import "firebase/database";

export const buzz = (roomId, playerId) => {
  // create a 'buzz' event on the 'buzzes' collection
  firebase.database().ref(`/rooms/${roomId}/buzzes`).push({
    playerId,
    serverTime: firebase.database.ServerValue.TIMESTAMP,
    clientTime: Date.now(),
  });
};

export const answerFinalJeopardy = (roomId, playerId, answer) => {
  firebase.database().ref(`/rooms/${roomId}/finalJeopardyAnswers`).push({
    playerId,
    answer,
  });
};

export const setFinalJeopardyWager = (roomId, playerId, wager) => {
  firebase.database().ref(`/rooms/${roomId}/finalJeopardyWagers`).push({
    playerId,
    wager,
  });
};

export const finalJeopardyWagers = (roomId, playerId, wager) => {
  firebase.database().ref(`/rooms/${roomId}/finalJeopardyWagers`).push({
    playerId,
    wager,
  });
};
