import React, { useState } from "react";
import {
  Form,
  Segment,
  Header,
  Message,
  Button,
  Modal,
} from "semantic-ui-react";
import { setFinalJeopardyWager } from "../../game/player";

const getMaxWager = (scores, playerId) => {
  const score = (scores || {})[playerId] || 0;
  return score < 0 ? 0 : score;
};

const canClickSubmitWagerButton = (wager, scores, playerId) => {
  const parsed = parseInt(wager);
  if (isNaN(parsed)) {
    return false;
  } else {
    return parsed >= 0 && parsed <= getMaxWager(scores, playerId);
  }
};

const parseWagerAsNumber = (wager) => Math.round(Number(wager));

const wagerIsActuallyValid = (wager, scores, playerId) => {
  const parsed = parseWagerAsNumber(wager);
  if (isNaN(parsed)) {
    return false;
  } else {
    return parsed >= 0 && parsed <= getMaxWager(scores, playerId);
  }
};

export default ({ activeRound, playerId, roomId, scores }) => {
  const [wager, setWager] = useState("");
  const [hahaFuckYouYouFuckingClownModal, setHahaFuckYouYouFuckingClownModal] =
    useState(false);
  // todo make this work right
  const [submitted, setSubmitted] = useState(false);

  const parsesWithParseInt = canClickSubmitWagerButton(wager, scores, playerId);
  const wagerIsValid = wagerIsActuallyValid(wager, scores, playerId);
  // const wagerInvalid = !isWagerValid(wager, scores, playerId);
  return (
    <Segment>
      <Header>{activeRound.category}</Header>
      <Form error={!parsesWithParseInt} success={submitted}>
        <Form.Input
          label="Wager"
          onChange={(_, { value }) => setWager(value)}
        />
        <Button
          disabled={!parsesWithParseInt || submitted}
          positive
          primary
          size="huge"
          content="Submit"
          onClick={() => {
            if (!wagerIsValid) {
              setHahaFuckYouYouFuckingClownModal(true);
            } else {
              setSubmitted(true);
              setFinalJeopardyWager(
                roomId,
                playerId,
                parseWagerAsNumber(wager)
              );
            }
          }}
        />
        <HahaFuckYouYouFuckingClownModal
          open={hahaFuckYouYouFuckingClownModal}
          closer={setHahaFuckYouYouFuckingClownModal}
        />
        <Message
          error
          header="Wager invalid"
          content={`Your wager must be a number greater than 0 and smaller than or equal to ${getMaxWager(
            scores,
            playerId
          )}`}
        />
        <Message
          success
          header="Wager submitted"
          content="Wait for the question to be presented"
        />
      </Form>
    </Segment>
  );
};

const HahaFuckYouYouFuckingClownModal = ({ open, closer }) => (
  <Modal
    open={open}
    closeIcon={false}
    closeOnEscape={false}
    actions={[
      {
        positive: true,
        onClick: () => closer(false),
        content: `\u{1f921} I am a sad clown and I am very sorry for my actions, though i know i can never be forgiven`,
      },
    ]}
    header={`\u{1f921} haha fuck you you fucking joker`}
    content={`\u{1f921} you think you're fucking funny giving me a wager that's not a number? alex trebek would shit on you you fucking clown. hahaha go fuck yourself you fucking clown. \u{1f921}`}
  />
);
