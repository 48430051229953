import firebase from "firebase/app";
import "firebase/database";
import { randomStr } from "../util";
import { maxBy } from "lodash";
import * as Paths from "./paths";
export const createRoom = (gameId, roomName) => {
  const hostRejoinPassword = randomStr(10);
  const roomData = {
    host: {
      hostRejoinPassword,
      gameId,
    },
    shared: {
      roomReadyToStart: false,
      buzzable: false,
      roomName,
      round: null,
      players: {},
    },
  };
  return firebase.database().ref().child("rooms").push(roomData).key;
};

export const createTestFJRoom = () => {
  const roomData = {
    isTestRoom: true,
    host: {
      gameId: "5225",
    },
    shared: {
      roomReadyToStart: true,
      players: {
        haha: {
          name: "haha",
        },
        science: {
          name: "science",
        },
      },
      scores: {
        haha: 500,
        science: 450,
      },
      activeRound: {
        type: "final_jeopardy",
        stage: "wager",
        category: "DIRIGIBLES",
      },
    },
  };
  const key = firebase.database().ref().child("rooms").push(roomData).key;
  return key;
};

const prepareQuestionsForRoom = (questions) => {
  return Object.entries(questions)
    .map(([id, { index, clueText, category, points }]) => ({
      id,
      index,
      category,
      missingQuestionText: !clueText,
      chosen: false,
      points,
    }))
    .sort((a, b) => a.index - b.index);
};

export const makeRoomReady = (roomId, gameId) => {
  firebase
    .database()
    .ref(`${Paths.episodePath(gameId)}/jeopardy`)
    .once("value", (round) => {
      const { questions, categories } = round.val();
      const gameBoard = prepareQuestionsForRoom(questions);

      firebase
        .database()
        .ref(`/rooms/${roomId}/shared`)
        .transaction((room) => {
          if (room) {
            const playerIds = Object.keys(room.players);
            const goesFirst =
              playerIds[Math.floor(Math.random() * playerIds.length)];
            return {
              ...room,
              roomReadyToStart: true,
              playerWithBoardControl: goesFirst,
              activeRound: {
                type: "jeopardy",
                categories: categories,
                gameBoard,
              },
            };
          } else {
            return room;
          }
        });
    });
};

export const selectQuestion = async (
  gameId,
  roomId,
  round,
  questionIndex,
  questionId
) => {
  let clueAnswer;
  // Copy the question text to the room and clear the active answer, if it exists.
  const question = await firebase
    .database()
    .ref(`${Paths.episodePath(gameId)}/${round}/questions/${questionId}`)
    .once("value")
    .then((s) => s.val());

  const { clueText, points, isDailyDouble } = question;
  // ingester-v2 produces the answer here
  clueAnswer = question.clueAnswer;

  // ingester-v1 uses a different part of Firebase - the answers path
  // if the answer isn't found as part of the question, then load it from there.
  if (!clueAnswer) {
    clueAnswer = await firebase
      .database()
      .ref(`${Paths.answersPath(questionId)}/clueAnswer`)
      .once("value")
      .then((s) => s.val());
  }

  firebase
    .database()
    .ref(`/rooms/${roomId}`)
    .transaction(({ shared, host }) => ({
      shared: {
        ...shared,
        activeQuestionIsDailyDouble: isDailyDouble,
        activeQuestionNeedsDailyDoubleWager: isDailyDouble,
        activeQuestionText: clueText,
        activeAnswerText: null,
      },
      host: {
        ...host,
        activeAnswerText: clueAnswer,
        pointsAtStake: isDailyDouble ? null : points,
        questionIndex,
      },
      buzzes: null,
    }));
};

export function submitDailyDoubleWager(roomId, wager) {
  firebase
    .database()
    .ref(`/rooms/${roomId}`)
    .transaction(({ shared, host }) => ({
      shared: {
        ...shared,
        activeQuestionNeedsDailyDoubleWager: false,
      },
      host: {
        ...host,
        pointsAtStake: wager,
      },
    }));
}

export const allowBuzzes = (roomId) => {
  firebase.database().ref(`/rooms/${roomId}/shared`).update({ buzzable: true });
};

const updatedScores = (scores, playerId, pointsAtStake, increase) => {
  const scoresObj = scores || {};
  const currentScore = scoresObj[playerId] || 0;
  const multiplier = increase ? 1 : -1;
  scoresObj[playerId] = currentScore + multiplier * pointsAtStake;
  return scoresObj;
};

const isRoundOver = (activeRound) =>
  activeRound.gameBoard.every(
    ({ chosen, missingQuestionText }) => chosen || missingQuestionText
  );

const updateRoundAfterQuestionOver = (activeRound, questionIndex) => {
  activeRound.gameBoard[questionIndex].chosen = true;
  // test if round is over
  if (isRoundOver(activeRound, questionIndex)) {
    return null;
  } else {
    return activeRound;
  }
};

export const loadNextRound = (roomId, gameId, currentRoundType) => {
  const nextRoundType =
    currentRoundType === "jeopardy" ? "double_jeopardy" : "final_jeopardy";

  if (nextRoundType === "double_jeopardy") {
    firebase
      .database()
      .ref(`${Paths.episodePath(gameId)}/double_jeopardy`)
      .once("value")
      .then((snapshot) => {
        const { categories, questions } = snapshot.val();
        const gameBoard = prepareQuestionsForRoom(questions);
        firebase
          .database()
          .ref(`/rooms/${roomId}/shared`)
          .update({
            loadingNextRound: false,
            activeRound: {
              type: "double_jeopardy",
              categories,
              gameBoard,
            },
          });
      });
  } else {
    firebase
      .database()
      .ref(`${Paths.episodePath(gameId)}/final_jeopardy`)
      .once("value")
      .then((snapshot) => {
        const { category } = snapshot.val();
        firebase
          .database()
          .ref(`/rooms/${roomId}/shared`)
          .update({
            loadingNextRound: false,
            playerWithBoardControl: null,
            activeRound: {
              type: "final_jeopardy",
              stage: "wager",
              category,
            },
          });
      });
  }
};

export const revealFinalJeopardyQuestion = (roomId, gameId) => {
  firebase
    .database()
    .ref(`${Paths.episodePath(gameId)}/final_jeopardy`)
    .once("value")
    .then((snapshot) => {
      const response = snapshot.val();
      const clueText =
        response.clueText || Object.values(response.question)[0].clueText;
      const clueAnswer =
        response.clueAnswer || Object.values(response.question)[0].clueAnswer;
      firebase
        .database()
        .ref(`/rooms/${roomId}/shared`)
        .update({
          loadingNextRound: false,
          playerWithBoardControl: null,
          activeRound: {
            type: "final_jeopardy",
            stage: "answer",
            category: response.category,
            question: clueText,
          },
        });
      return clueAnswer;
    })
    .then((clueAnswer) =>
      firebase.database().ref(`/rooms/${roomId}/host`).update({
        finalJeopardyAnswer: clueAnswer,
      })
    );
};

export const updatePlayerScore = (roomId, playerId, newScore) => {
  firebase
    .database()
    .ref(`/rooms/${roomId}/shared/scores/${playerId}`)
    .set(newScore);
};

export const pullQuestionAfterWrongAnswers = (roomId, answer) => {
  let loadingNextRound = false;
  let gameId = null;
  let roundType = null;
  firebase
    .database()
    .ref(`/rooms/${roomId}`)
    .transaction(({ shared, host }) => {
      const updatedRound = updateRoundAfterQuestionOver(
        shared.activeRound,
        host.questionIndex
      );
      loadingNextRound =
        updatedRound === null && shared.activeRound.type !== "final_jeopardy";
      gameId = host.gameId;
      roundType = shared.activeRound.type;
      return {
        shared: {
          ...shared,
          buzzable: false,
          activeQuestionText: null,
          activeQuestionId: null,
          activeAnswerText: answer,
          buzzedInPlayerId: null,
          buzzes: null,
          activeRound: updatedRound,
          loadingNextRound,
        },
        host: {
          ...host,
          activeAnswerText: null,
          pointsAtStake: null,
          questionIndex: null,
        },
        buzzes: null,
      };
    })
    .then(() => {
      if (loadingNextRound) {
        loadNextRound(roomId, gameId, roundType);
      }
    });
};

export const markCorrectAndContinue = (
  roomId,
  buzzedInPlayerId,
  pointsAtStake,
  scores,
  answer
) => {
  let loadingNextRound = false;
  let gameId = null;
  let roundType = null;
  firebase
    .database()
    .ref(`/rooms/${roomId}`)
    .transaction(({ shared, host }) => {
      const updatedRound = updateRoundAfterQuestionOver(
        shared.activeRound,
        host.questionIndex
      );
      loadingNextRound =
        updatedRound === null && shared.activeRound.type !== "final_jeopardy";
      gameId = host.gameId;
      roundType = shared.activeRound.type;
      return {
        shared: {
          ...shared,
          buzzable: false,
          activeQuestionText: null,
          activeQuestionId: null,
          activeAnswerText: answer,
          playerWithBoardControl: buzzedInPlayerId,
          scores: updatedScores(scores, buzzedInPlayerId, pointsAtStake, true),
          buzzedInPlayerId: null,
          loadingNextRound,
          activeRound: updatedRound,
        },
        host: { ...host, questionIndex: null },
        buzzes: null,
      };
    })
    .then(() => {
      if (loadingNextRound) {
        loadNextRound(roomId, gameId, roundType);
      }
    });
};

export const markWrong = (roomId, playerId, scores, pointsAtStake) => {
  firebase
    .database()
    .ref(`/rooms/${roomId}`)
    .transaction(({ shared, host }) => ({
      shared: {
        ...shared,
        scores: updatedScores(scores, playerId, pointsAtStake, false),
        buzzable: true,
        buzzedInPlayerId: null,
      },
      host,
      buzzes: null,
    }));
};

export const markWrongAndContinue = (
  roomId,
  playerId,
  scores,
  pointsAtStake,
  answer
) => {
  let loadingNextRound = false;
  let gameId = null;
  let roundType = null;
  firebase
    .database()
    .ref(`/rooms/${roomId}`)
    .transaction(({ shared, host }) => {
      const updatedRound = updateRoundAfterQuestionOver(
        shared.activeRound,
        host.questionIndex
      );
      loadingNextRound =
        updatedRound === null && shared.activeRound.type !== "final_jeopardy";
      gameId = host.gameId;
      roundType = shared.activeRound.type;
      return {
        shared: {
          ...shared,
          scores: updatedScores(scores, playerId, pointsAtStake, false),
          buzzable: false,
          buzzedInPlayerId: null,
          activeQuestionText: null,
          activeQuestionId: null,
          activeAnswerText: answer,
          playerWithBoardControl: playerId,
          loadingNextRound,
          activeRound: updatedRound,
        },
        host: { ...host, questionIndex: null },
        buzzes: null,
      };
    })
    .then(() => {
      if (loadingNextRound) {
        loadNextRound(roomId, gameId, roundType);
      }
    });
};

export const setBuzzedInPlayer = (roomId, buzzedInPlayerId) => {
  if (buzzedInPlayerId) {
    firebase.database().ref(`/rooms/${roomId}/shared`).update({
      buzzable: false,
      buzzedInPlayerId,
    });
  }
};

export const gradeFinalJeopardy = (roomId, answerId, wager, correct) => {
  const scoreChange = (correct ? 1 : -1) * wager;
  firebase
    .database()
    .ref(`/rooms/${roomId}/finalJeopardyAnswers/${answerId}`)
    .update({
      scoreChange,
    });
};

const answersByPlayerId = (finalJeopardyAnswers) => {
  const answersArray = Object.values(finalJeopardyAnswers);
  let answersObj = {};
  for (let i = 0; i < answersArray.length; i++) {
    answersObj[answersArray[i].playerId] = answersArray[i];
  }
  return answersObj;
};

export const endGame = (roomId, players, scores) => {
  firebase
    .database()
    .ref(`/rooms/${roomId}/shared`)
    .update({
      gameOver: true,
      activeRound: null,
    })
    .then(() => {
      return firebase
        .database()
        .ref(`/rooms/${roomId}/finalJeopardyAnswers`)
        .once("value")
        .then((snapshot) => {
          const finalJeopardyAnswers = answersByPlayerId(snapshot.val());

          const playerIds = Object.keys(players);
          for (let i = 0; i < playerIds.length; i++) {
            const playerId = playerIds[i];
            const scoreChange =
              (finalJeopardyAnswers[playerId] &&
                finalJeopardyAnswers[playerId].scoreChange) ||
              0;
            const currentScore = scores[playerId] || 0;
            scores[playerId] = currentScore + scoreChange;
          }

          const [winner] = maxBy(
            Object.entries(scores),
            ([_id, value]) => value
          );

          firebase.database().ref(`/rooms/${roomId}/shared`).update({
            finalJeopardyAnswers,
            scores,
            winner,
          });
        });
    });
};
