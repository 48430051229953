import React from "react";
import firebase from "firebase/app";
import "firebase/database";
import { buzzedIn } from "../game/buzzedIn";
import { setBuzzedInPlayer } from "../game/host";

export const StoreContext = React.createContext({});

export class JeopardyGameStore extends React.Component {
  constructor(props) {
    super(props);
    this.setState.bind(this);
    this.state = {
      initial: true,
      roomDoesNotExist: false,
      loading: true,
      gameId: null,
      game: {},
      roomId: this.props.roomId,
    };
  }

  componentDidMount() {
    const { roomId } = this.props;
    firebase
      .database()
      .ref(`/rooms/${roomId}`)
      .on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const {
            host,
            shared,
            buzzes,
            finalJeopardyAnswers,
            finalJeopardyWagers,
          } = data;
          const buzzedInPlayerId = buzzedIn(buzzes);
          setBuzzedInPlayer(roomId, buzzedInPlayerId);
          this.setState({
            initial: false,
            loading: false,
            roomId,
            host,
            finalJeopardyAnswers,
            finalJeopardyWagers,
            game: {
              ...shared,
              players: shared.players || {},
              buzzedInPlayerId,
            },
          });
        } else {
          this.setState({ roomDoesNotExist: true });
        }
      });
  }

  render() {
    return (
      <StoreContext.Provider value={this.state}>
        {this.props.children}
      </StoreContext.Provider>
    );
  }
}
