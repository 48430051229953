import React from "react";
import { JeopardyGameStore } from "../../store";
import HostDash from "./HostDash";
import { Container } from "semantic-ui-react";
export default ({
  match: {
    params: { roomId },
  },
}) => {
  return (
    <Container>
      <JeopardyGameStore roomId={roomId}>
        <HostDash />
      </JeopardyGameStore>
    </Container>
  );
};
