import React, { useState } from "react";
import { Form, Segment, Header, Message, Button } from "semantic-ui-react";
import { answerFinalJeopardy } from "../../game/player";
import ReactMarkdown from "react-markdown";

export default ({ activeRound, playerId, roomId }) => {
  const [answer, setAnswer] = useState("");
  // todo make this work right
  const [submitted, setSubmitted] = useState(false);
  return (
    <Segment>
      <Header>{activeRound.category}</Header>
      <ReactMarkdown source={activeRound.question} />
      <Form success={submitted}>
        <Form.Input
          label="Answer"
          onChange={(_, { value }) => setAnswer(value)}
        />
        <Button
          disabled={answer === "" || submitted}
          positive
          primary
          size="huge"
          content="Submit"
          onClick={() => {
            setSubmitted(true);
            answerFinalJeopardy(roomId, playerId, answer);
          }}
        />
        <Message
          success
          header="Answer submitted"
          content="Wait for the game to end"
        />
      </Form>
    </Segment>
  );
};
