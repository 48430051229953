import React from "react";
import { Button, List } from "semantic-ui-react";
import { gradeFinalJeopardy } from "../../game/host";

const getByPlayer = (wagers, defaultValue) => {
  const wagersArr = Object.values(wagers || {});
  const wagersObj = {};
  for (let i = 0; i < wagersArr.length; i++) {
    const { playerId, wager } = wagersArr[i];
    wagersObj[playerId] = wager || defaultValue;
  }
  return wagersObj;
};
const disableButtons = (scoreChange) => scoreChange !== undefined;
export default ({
  finalJeopardyAnswers,
  finalJeopardyWagers,
  roomId,
  players,
}) => {
  const wagersByPlayer = getByPlayer(finalJeopardyWagers || {}, 0);
  return (
    <List>
      {Object.entries(finalJeopardyAnswers || {}).map(
        ([key, { answer, playerId, scoreChange }]) => {
          const wager = wagersByPlayer[playerId];
          return (
            <List.Item key={`fjlist${key}`}>
              <List.Content floated="right">
                <Button.Group>
                  <Button
                    size="small"
                    disabled={disableButtons(scoreChange)}
                    negative
                    content={`-${wager}`}
                    onClick={() =>
                      gradeFinalJeopardy(roomId, key, wager, false)
                    }
                  />
                  <Button
                    size="small"
                    disabled={disableButtons(scoreChange)}
                    positive
                    content={`+${wager}`}
                    onClick={() => gradeFinalJeopardy(roomId, key, wager, true)}
                  />
                </Button.Group>
              </List.Content>
              <List.Content>
                <List.Header>{players[playerId].name}</List.Header>
                {answer}
              </List.Content>
            </List.Item>
          );
        }
      )}
    </List>
  );
};
