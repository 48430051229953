import React from "react";
import firebase from "firebase/app";
import "firebase/database";

export const PlayerContext = React.createContext({});

export class PlayerStore extends React.Component {
  constructor(props) {
    super(props);
    this.setState.bind(this);
    this.state = {
      initial: true,
      roomDoesNotExist: false,
      loading: true,
      game: {},
      roomId: null,
    };
  }

  componentDidMount() {
    const { playerName, roomId } = this.props;
    firebase
      .database()
      .ref(`/rooms/${roomId}/shared`)
      .on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          // We need to check players to see if a player with this name already exists,
          // if it does, we join as that player (we assume that everyone is good and no one is  trying
          // to cheat). Otherwise, we'll create the player.
          const playerId = Object.keys(data.players || {}).find(
            (key) => data.players[key].name === playerName
          );

          if (!playerId) {
            // if player with this player name not yet in firebase, push new player
            firebase
              .database()
              .ref(`/rooms/${roomId}/shared/players`)
              .push({ name: playerName });
          } else {
            // otherwise, read updates from firebase and save them to playerstate
            const updates = {
              roomId,
              playerId,
              game: {
                ...data,
                players: data.players || {},
              },
            };
            this.setState(updates);
          }
        } else {
          console.log(playerName, roomId);
          this.setState({ roomDoesNotExist: true });
        }
      });
  }

  render() {
    return (
      <PlayerContext.Provider value={this.state}>
        {this.props.children}
      </PlayerContext.Provider>
    );
  }
}
