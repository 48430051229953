import React from "react";
import { Message, Table } from "semantic-ui-react";

export default ({ finalJeopardyAnswers, players, scores, winner }) => {
  return (
    <>
      <Message
        success
        header={`\u{1f389} \u{1f389} \u{1f389} ${players[winner].name} won!`}
      />
      <Table celled unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign="center" content="Name" />
            <Table.HeaderCell textAlign="center" content="Answer" />
            <Table.HeaderCell textAlign="center" content="Change" />
            <Table.HeaderCell textAlign="center" content="Final Score" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(finalJeopardyAnswers)
            .sort(([id1, v1], [id2, v2]) => scores[id1] - scores[id2])
            .map(([key, { answer, wager, playerId, scoreChange }]) => (
              <Table.Row key={`finalscores${playerId}`}>
                <Table.Cell>{players[playerId].name}</Table.Cell>
                <Table.Cell>{answer}</Table.Cell>
                <Table.Cell
                  negative={scoreChange < 0}
                  positive={scoreChange > 0}
                >
                  {scoreChange}
                </Table.Cell>
                <Table.Cell>{scores[playerId]}</Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    </>
  );
};
