import React from "react";
import { Header } from "semantic-ui-react";

const prefix = (roundType) => {
  switch (roundType) {
    case "jeopardy":
      return null;
    case "double_jeopardy":
      return "double";
    case "final_jeopardy":
      return "final";
    case null:
      return null;
    default:
      return null;
  }
};

export default ({ roundType }) => (
  <Header as="h2" textAlign="center" style={{ marginTop: "1em" }}>
    {prefix(roundType) && <i>{prefix(roundType)} </i>}dougie jeopardy!
  </Header>
);
