import React, { useState } from "react";
import { Message, List, Popup, Input, Button } from "semantic-ui-react";
import { updatePlayerScore } from "../game/host";

const formatScore = (score) => {
  return score < 0 ? `-$${score * -1}` : `$${score}`;
};
export default ({ roomId, players, scores, hostMode }) => {
  const playersObj = players || {};
  const scoresObj = scores || {};
  const numPlayers = Object.values(playersObj).length;
  return numPlayers > 0 ? (
    <Message>
      <Message.Header>Players ({numPlayers})</Message.Header>
      <List horizontal>
        {Object.entries(playersObj)
          .sort(([_a, a], [_b, b]) => (a.name > b.name ? 1 : -1))
          .map(([key, { name }]) => (
            <List.Item key={name}>
              <List.Content>
                <List.Header>
                  {hostMode ? (
                    <ScoreAdjustmentPopup
                      roomId={roomId}
                      playerId={key}
                      name={name}
                      score={scoresObj[key] || 0}
                    />
                  ) : (
                    name
                  )}
                </List.Header>
                {formatScore(scoresObj[key] || 0)}
              </List.Content>
            </List.Item>
          ))}
      </List>
    </Message>
  ) : null;
};

const parseScoreAsNumber = (score) => Math.round(Number(score));
const scoreIsValid = (score) => !isNaN(parseScoreAsNumber(score));

const ScoreAdjustmentPopup = ({ roomId, playerId, name, score }) => {
  const [inputScore, setInputScore] = useState(score);
  return (
    <Popup on="click" trigger={<a href={`#scoreAdjust/${playerId}`}>{name}</a>}>
      <Input
        value={inputScore}
        onChange={(_, { value }) => setInputScore(value)}
      />
      <Button
        content="Update score"
        disabled={inputScore === "" || !scoreIsValid(inputScore)}
        onClick={() => {
          const parsed = parseScoreAsNumber(inputScore);
          updatePlayerScore(roomId, playerId, parsed);
        }}
      />
    </Popup>
  );
};
