import React, { useState } from "react";
import { Button, Container, Form, Segment } from "semantic-ui-react";
import { createRoom, createTestFJRoom } from "../../game/host";
import { JeopardyGameStore } from "../../store";
import PageTitle from "../PageTitle";
import HostDash from "./HostDash";

export default () => {
  const [roomId, setRoomId] = useState(null);
  const [episodeId, setEpisodeId] = useState(null);
  return (
    <Container>
      {roomId ? (
        <JeopardyGameStore roomId={roomId}>
          <HostDash />
        </JeopardyGameStore>
      ) : (
        <>
          <PageTitle />
          <Form>
            <Form.Input
              label="Episode ID (get this from an admin)"
              onChange={(_, { value }) => setEpisodeId(value)}
            />
            <Button.Group>
              <Button
                disabled={!episodeId}
                positive
                primary
                size="huge"
                content="Start!"
                onClick={() => {
                  setRoomId(createRoom(episodeId, "there are no room names"));
                }}
              />
              {process.env.NODE_ENV === "development" ? (
                <Button
                  content="Use Test Game"
                  onClick={() =>
                    setRoomId(createRoom("5225", "Ok, sounds like fun"))
                  }
                />
              ) : null}
              {process.env.NODE_ENV === "development" ? (
                <Button
                  content="Final Jeopardy Test Mode"
                  onClick={() => {
                    const roomId = createTestFJRoom();
                    console.log("using room id", roomId);
                    setRoomId(roomId);
                  }}
                />
              ) : null}
            </Button.Group>
          </Form>
          <Segment>
            Joining a game? <a href="/">Click here.</a>
          </Segment>
        </>
      )}
    </Container>
  );
};
