import { useContext } from "react";
import { PlayerContext } from "../../store/PlayerStore";
import { useCallback } from "react";
import { buzz } from "../../game/player";
import { useEffect } from "react";
import * as Mousetrap from "mousetrap";
export default () => {
  const { roomId, game, playerId } = useContext(PlayerContext);
  const buzzable = game && game.buzzable;
  const buzzCallback = useCallback(() => {
    if (buzzable) {
      buzz(roomId, playerId);
    }
  }, [buzzable, roomId, playerId]);

  useEffect(() => {
    Mousetrap.bind(["enter", "space"], buzzCallback);
  }, [buzzCallback]);

  return null;
};
