import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./App.css";
import HostSite from "./components/HostSite";
import HostRejoin from "./components/HostSite/HostRejoin";
import PlayerSite from "./components/PlayerSite";

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path="/hostRejoin/:roomId" component={HostRejoin} />
      <Route path="/host">
        <HostSite />
      </Route>
      <Route path="/join/:roomId" component={PlayerSite} />
      <Route path="/">
        <PlayerSite />
      </Route>
    </Switch>
  </BrowserRouter>
);
