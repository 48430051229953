import React, { useContext } from "react";
import { Button, Card, Message } from "semantic-ui-react";
import { makeRoomReady, loadNextRound } from "../../game/host";
import { StoreContext } from "../../store";
import firebase from "firebase/app";
import "firebase/database";
import { randomStr } from "../../util";
import Board from "../Board";
import FinalScores from "../FinalScores";
import Loader from "../Loader";
import PageTitle from "../PageTitle";
import PlayerAwareBoardMessage from "../PlayerAwareBoardMessage";
import PlayerList from "../PlayerList";
import FinalJeopardyControl from "./FinalJeopardyControl";
import FinalJeopardyWagerManager from "./FinalJeopardyWagerManager";
import HostQuestionModal from "./HostQuestionModal";
export default () => {
  const {
    roomId,
    loading,
    initial,
    host,
    game,
    finalJeopardyAnswers,
    finalJeopardyWagers,
  } = useContext(StoreContext);
  const joinUrl = `https://jeopardy.dougie.tech/join/${roomId}`;
  return loading || initial ? (
    <Loader isLoading />
  ) : (
    <>
      <PageTitle roundType={game.activeRound && game.activeRound.type} />
      <HostQuestionModal
        activeRound={game.activeRound}
        pointsAtStake={host.pointsAtStake}
        scores={game.scores}
        buzzedInPlayerId={game.buzzedInPlayerId}
        activeQuestionIsDailyDouble={game.activeQuestionIsDailyDouble}
        activeQuestionText={game.activeQuestionText}
        activeAnswerText={host.activeAnswerText}
        players={game.players}
        playerWithBoardControl={game.playerWithBoardControl}
        buzzable={game.buzzable}
        roomId={roomId}
      />
      {game.roomReadyToStart ? null : (
        <Card fluid>
          <Card.Content>
            <Card.Header>Your room code is {roomId}</Card.Header>
            <Card.Description>
              Send this link to your friends: {joinUrl}
            </Card.Description>
          </Card.Content>
          <Card.Content extra>
            <Button.Group>
              <Button
                icon="copy"
                content="Copy join link"
                onClick={() => navigator.clipboard.writeText(joinUrl)}
              />
              <Button
                disabled={
                  game.players && Object.values(game.players).length < 2
                }
                content="READY TO PLAY"
                primary
                onClick={() => makeRoomReady(roomId, host.gameId)}
              />
            </Button.Group>
          </Card.Content>
        </Card>
      )}
      {game.playerWithBoardControl ? (
        <PlayerAwareBoardMessage
          playerId={game.playerWithBoardControl}
          players={game.players}
          header="control of the board."
          message="pick the next question."
        />
      ) : null}
      {game.activeRound && game.activeRound.type !== "final_jeopardy" ? (
        <Board
          gameId={host.gameId}
          roomId={roomId}
          activeRound={game.activeRound}
          hostMode={true}
        />
      ) : null}
      {game.activeRound &&
      game.activeRound.type === "final_jeopardy" &&
      game.activeRound.stage === "wager" ? (
        <FinalJeopardyWagerManager
          activeRound={game.activeRound}
          finalJeopardyWagers={finalJeopardyWagers}
          players={game.players}
          gameId={host.gameId}
          roomId={roomId}
        />
      ) : null}
      {game.activeRound &&
      game.activeRound.type === "final_jeopardy" &&
      game.activeRound.stage === "answer" ? (
        <FinalJeopardyControl
          activeRound={game.activeRound}
          finalJeopardyAnswer={host.finalJeopardyAnswer}
          finalJeopardyAnswers={finalJeopardyAnswers}
          finalJeopardyWagers={finalJeopardyWagers}
          players={game.players}
          scores={game.scores}
          roomId={roomId}
        />
      ) : null}
      {game.finalJeopardyAnswers ? (
        <FinalScores
          scores={game.scores}
          players={game.players}
          finalJeopardyAnswers={game.finalJeopardyAnswers}
          winner={game.winner}
        />
      ) : (
        <PlayerList
          roomId={roomId}
          players={game.players}
          scores={game.scores}
          hostMode={true}
        />
      )}
      {process.env.NODE_ENV === "development" ? (
        <Message
          header="Development Tools"
          content={
            <Button.Group>
              <Button
                content="Next round"
                onClick={() =>
                  loadNextRound(roomId, host.gameId, game.activeRound.type)
                }
              />
              <Button
                content="Add player"
                onClick={() =>
                  firebase
                    .database()
                    .ref(`/rooms/${roomId}/shared/players`)
                    .push({ name: randomStr(10) })
                }
              />
            </Button.Group>
          }
        />
      ) : null}
    </>
  );
};
