import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "semantic-ui-css/semantic.min.css";
import firebase from "firebase/app";

var firebaseConfig = {
  apiKey: "AIzaSyCpxjfyXfUJqYpNKDvU6UddmrcS46aCwh8",
  authDomain: "jeopardy-272202.firebaseapp.com",
  databaseURL: "https://jeopardy-272202.firebaseio.com",
  projectId: "jeopardy-272202",
  storageBucket: "jeopardy-272202.appspot.com",
  messagingSenderId: "1090603266694",
  appId: "1:1090603266694:web:1b3e2f4c5f2dc7594d1707",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

ReactDOM.render(<App />, document.getElementById("root"));
