import React from "react";
import { Table } from "semantic-ui-react";
import { selectQuestion } from "../game/host";

/**
 * Provides the board view as a table to players and the host.
 * When 'hostMode' is true, the cells become clickable and control
 * of the game can be established.
 */
export default ({
  hostMode,
  gameId,
  roomId,
  activeRound: { type, categories, gameBoard },
}) => (
  <Table celled unstackable>
    <Table.Header>
      <Table.Row>
        {categories.map((category) => (
          <Table.HeaderCell textAlign="center" key={category}>
            {category}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>

    <Table.Body>
      {[...Array(5).keys()].map((i) => {
        return (
          <Table.Row key={`row-${i}`}>
            {[...Array(6).keys()].map((j) => {
              const boardIndex = j * 5 + i;
              const { missingQuestionText, chosen, points, id } =
                gameBoard[boardIndex];
              const text = missingQuestionText || chosen ? null : `$${points}`;
              const selectable = hostMode && !chosen && !missingQuestionText;
              return (
                <Table.Cell textAlign="center" selectable={selectable} key={id}>
                  {selectable ? (
                    <a
                      href={`#${id}`}
                      onClick={() =>
                        selectQuestion(gameId, roomId, type, boardIndex, id)
                      }
                    >
                      {text}
                    </a>
                  ) : (
                    text
                  )}
                </Table.Cell>
              );
            })}
          </Table.Row>
        );
      })}
    </Table.Body>
  </Table>
);
