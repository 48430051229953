import React, { useState } from "react";
import { Button, Container, Form, Message, Segment } from "semantic-ui-react";
import { PlayerStore } from "../../store/PlayerStore";
import PageTitle from "../PageTitle";
import PlayerDash from "./PlayerDash";
// this is a nonsense wrapper around the actual game.
export default (props) => {
  const roomId = props.match && props.match.params && props.match.params.roomId; // lol
  console.log("Room Id", roomId);
  const [roomIdFormState, setRoomId] = useState("");
  const [name, setName] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [formError, setFormError] = useState(null);

  return (
    <Container>
      {!formSubmit ? (
        <>
          <PageTitle />
          <Form error={!!formError}>
            <Message error content={formError} />
            <Form.Input
              label="Name"
              onChange={(_, { value }) => setName(value)}
            />
            {!roomId && (
              <Form.Input
                label="Room Code"
                onChange={(_, { value }) => setRoomId(value)}
              />
            )}
            <Button
              disabled={name !== "" && !roomId && roomIdFormState.length !== 20}
              positive
              primary
              size="huge"
              content="JOIN"
              onClick={() => {
                setFormSubmit(true);
                setFormError(null);
              }}
            />
          </Form>
          <Segment>
            Want to start your own game? <a href="/host">Click here.</a>
          </Segment>
        </>
      ) : (
        <PlayerStore roomId={roomId || roomIdFormState} playerName={name}>
          <PlayerDash
            returnToForm={() => {
              setFormError(
                `Sorry, ${roomId || roomIdFormState} does not exist.`
              );
              setFormSubmit(false);
            }}
          />
        </PlayerStore>
      )}
    </Container>
  );
};
