import React from "react";
import { Message } from "semantic-ui-react";

const getPlayerNameAndConjugatedVerb = (
  playerId,
  players,
  currentPlayerPlayerId
) => {
  return !!currentPlayerPlayerId && currentPlayerPlayerId === playerId
    ? "You have"
    : `${players[playerId].name} has`;
};
export default ({
  playerId,
  players,
  header,
  message,
  currentPlayerPlayerId,
}) => {
  const success = playerId === currentPlayerPlayerId;
  const messagePronoun = success ? "You" : "They";
  return (
    <Message
      success={success}
      header={`${getPlayerNameAndConjugatedVerb(
        playerId,
        players,
        currentPlayerPlayerId
      )} ${header}`}
      content={`${messagePronoun} ${message}`}
    />
  );
};
