import React from "react";
import { Segment, Dimmer, Loader } from "semantic-ui-react";
export default (isLoading) =>
  isLoading ? (
    <Segment placeholder>
      {" "}
      <Dimmer active inverted>
        <Loader active inline="centered" inverted>
          Getting questions ready...
        </Loader>
      </Dimmer>
    </Segment>
  ) : null;
